export const Utility = () => {
// 0 --> category; 1 --> sub-category; 2 --> courses
  const order = {
    "sgs": {
      "2": [[2089,2090,2091,2092],[1872,1756],[455,456,457,458]]
    },
    "naita":{
      "0":[12,8,9,13,14],
      "1":[84,85,9,86,12,17,18,19,20,21,79,23,26,27,29,31,34,67,68,69,74],
      "2":[[455,456,457,458]],
      "3658":["0_0","0_1","0_2"],
      "3644":["0_1","0_2","0_0"]
    }
  };
  const swap = (partner, data) => {
    console.log("swap");
    if(order[partner] === undefined || data === undefined)
      return;
    swapElements(0, data, partner);
    data.forEach((ele) => {
      swapElements(1, ele.sc, partner);

      ele.sc.forEach((element) => {
        swapElements(2, element.courses, partner);
      });
    });
  };
  const swapElements = (pos, data, partner) => {
    if(order[partner][pos] === undefined)
      return;
    order[partner][pos].forEach((el, index) => {
       data.forEach((ml) => {
        if(ml !== undefined && pos === 2){
          if(ml.cid !== undefined){
            if(el.includes(ml.cid) || el.includes(ml.id)){
              el.forEach((ele,index)=>{
                (ele === Number(ml.cid) || ele === Number(ml.id)) && swapele(index, data.indexOf(ml), data);
                
              })
            }
            if(order[partner][ml.cid] !== undefined){
              order[partner][ml.cid].forEach((cl, index) =>{
                if(cl === ml.config)
                  swapele(index, data.indexOf(ml), data);
              })
            }
          }
          
          
        }else if(ml !== undefined && pos !== 2){
          if(ml.id !== undefined)
          if(el === ml.id){
              swapele(index, data.indexOf(ml), data);
          }
          
        }
        
      });
    });
  };
  const swapele = (x, y, data) => {
    if(x < data.length && y < data.length){
      var z = data[y];
      data[y] = data[x];
      data[x] = z;
    }
  };
  return {
    swap,
  };
};

export default Utility;
